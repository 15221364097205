<template>
  <div class="home-splash">
    
    <b-container>
      
      <b-card class="mt-5">
        <div class="header-text">
          Ready to leave the Mormon church?
        </div>
        Resigning from the Mormon church (Church of Jesus Christ of Latter day Saints) can be a tedious and painful process. If you've
        decided that you no longer want to be a member of the church, resigning on your own can
        result in unwanted contact from church leaders and multiple requests before your resignation is finally
        processed. We provide a free service that lets you resign without the hassle.
        
        <b-row align-h="around"
               class="mt-3 mb-3">
          <b-col md="4">
            <b-button :to="{name: 'resignation-list'}"
                      block
                      variant="primary">
              Resign
            </b-button>
          
          </b-col>
          <b-col md="4">
            <b-button :to="{name: 'donate'}"
                      block
                      variant="secondary">Donate
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      
      <b-row class="mt-4">
        <b-col>
          
          <b-card-group deck>
            <b-card title="Why does this exist?">
              <p>We are not attempting to influence anyone to leave the church. However, if you have decided that you want to resign, using Quitmormon eliminates most or all
                unwanted contact and gets you resigned much quicker than resigning on your own.</p>
            </b-card>
            
            <b-card title="How it works">
              <p>Provide information about each person who wishes to resign and sign online using your computer, tablet, or phone.</p>
              <p>Our automated system will guide you through the resignation process and submit your resignation to church HQ.</p>
              <p>Your privacy is maintained and all communication is sent back to our office.</p>
            </b-card>
          
          </b-card-group>
        </b-col>
      </b-row>
    
    </b-container>
  
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {}
};
</script>

<style lang="scss"
       scoped>

#router-view {
  // view minus footer minus header minus padding
  min-height: calc(100vh - 60px - 80px);
}

.home-splash {
  display: flex;
  justify-content: center;
  
  background-position: center;
  background-image: url('../assets/bg_splash/tyssul-patel-o-zOatT4kQw-unsplash.jpg');
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  
  /*border: 1px solid yellow;*/
  
  .header-text {
    font-size: 3rem;
    font-family: Rubik, sans-serif;
    color: #192532;
  }
  
  .text-wrapper {
    /*border: 1px solid orange;*/
    /*display: block;*/
    /*width: 90%;*/
    /*margin: 0 auto;*/
    /*padding: 20px;*/
    /*background-color: rgba(255, 255, 255, 0.25);*/
  }
}


</style>
